//TODO: Convert positions from db into 2 channels and x and y for both.
import React, { useState, useEffect } from 'react';
import './App.css';
import client from './apis/db';
import { db } from './apis/db';
import PopulateItems from './components/populateItems';
import SavedGrids from './components/savedGrids';
import ColRowInput from './components/colRowInput';
import MoveLightButton from './components/moveLightButton';

const App = () => {
	const [ savedGrids, setSavedGrids ] = useState([]);
	const [ lightSettings, setLightSettings ] = useState({});
	const [ colCount, setColCount ] = useState(11);
	const [ rowCount, setRowCount ] = useState(17);
	const [ channel, setChannel ] = useState(1);
	const [ items, setItems ] = useState([]);
	const [ activeGrid, setActiveGrid ] = useState();
	const [ highlighted, setHighlighted ] = useState(-1);

	let startX = lightSettings.min_x;
	let endX = lightSettings.max_x;
	let startY = lightSettings.min_y;
	let endY = lightSettings.max_y;
	let totalWidth = startX + endX;
	let totalHeight = startY + endY;

	const changeActiveGrid = (value) => {
		setActiveGrid(value);
	};

	useEffect(
		() => {
			let newGrid = savedGrids.find((grid) => grid.name === activeGrid);
			setItems([]);
			if (newGrid) {
				// setRowCount(newGrid.data.length);
				// setColCount(newGrid.data[0].row.length);
				let positions = [];
				let dbCoords = newGrid.data.map((row) => {
					console.log(`ROW ITEM: ${JSON.stringify(row, null, 2)}`);
					positions.push({
						name: row.location_id,
						leftPosition: [ row.pwc1, row.twc1 ],
						centerPosition: [ row.pwc2, row.twc2 ],
						rightPosition: [ row.pwc3, row.twc3 ],
						select: false
					});
					// let rowArray = row.forEach((row) => {
					//
					// });
					//return rowArray;
				});

				console.log(`POSITIONS ARRAY: ${JSON.stringify(positions, null, 2)}`);

				PopulateItems(positions, colCount, rowCount, setItems);
			}
		},
		[ activeGrid ]
	);

	useEffect(() => {
		let gridsRefs = db.collection('grids');
		let getGrids = gridsRefs.onSnapshot((snapshot) => {
			let snapMap = snapshot.docs.map((doc) => {
				return { id: 'grid', data: doc.data().positions, name: 'grid' };
			});
			setSavedGrids(snapMap);
			setActiveGrid(snapMap[0].name);
		});

		return () => getGrids();
	}, []);

	useEffect(() => {
		client.lights.doc('channel' + channel).onSnapshot((currentSettings) => {
			setLightSettings(currentSettings.data());
		});
	}, []);

	return (
		<div
			className="App"
			style={{ marginLeft: 'auto', marginRight: 'auto', width: '200hv', backgroundColor: 'gray' }}
		>
			<SavedGrids savedGrids={savedGrids} changeActiveGrid={changeActiveGrid} />
			<ColRowInput title="Row Count" count={rowCount} setCount={setRowCount} />
			<ColRowInput title="Col Count" count={colCount} setCount={setColCount} />

			<div
				style={{
					width: 'auto',
					backgroundColor: 'white',
					alignSelf: 'center',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}
			>
				<div
					className="grid-container"
					style={{
						display: 'grid',
						gridTemplateColumns: `repeat(${colCount}, 1fr)`,
						gridTemplateRows: `repeat(${rowCount},1fr)`,
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					{items.map((item, index) => {
						console.log('MAP NAME', JSON.stringify(item));
						return (
							<div
								key={Math.random() * 10000000}
								style={
									index === highlighted ? (
										{ backgroundColor: 'yellow', border: '.5px solid grey' }
									) : (
										{ backgroundColor: 'white', border: '.5px solid grey' }
									)
								}
							>
								<span style={{ fontWeight: 'bold' }}>{item.name}</span>
								<br />
								<MoveLightButton
									client={client}
									index={index}
									item={item}
									items={items}
									setItems={setItems}
									highlighted={highlighted}
									setHighlighted={setHighlighted}
								/>
								<div
									style={{
										marginTop: '0px',
										marginBottom: '3px',
										fontSize: '08pt',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									<div style={{ width: '33%', display: 'inline-block' }}>
										L: <br />
										[{item.leftPosition[0]}, {item.leftPosition[1]}] <br />
									</div>
									<div style={{ width: '33%', display: 'inline-block' }}>
										C: <br />
										[{item.centerPosition[0]}, {item.centerPosition[1]}] <br />
									</div>
									<div style={{ width: '33%', display: 'inline-block' }}>
										R: <br />
										[{item.rightPosition[0]}, {item.rightPosition[1]}]
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default App;
