import React from 'react';

const ColRowInput = (props) => {
	const { setCount, count, title } = props;
	return (
		<div>
			<label style={{ paddingLeft: '10px' }}>{title}:</label>
			<input
				value={count}
				type="number"
				onChange={(val) => {
					val.target.value >= 0 && !isNaN(val.target.value)
						? setCount(val.target.value)
						: console.log('fail');
				}}
			/>
		</div>
	);
};
export default ColRowInput;
