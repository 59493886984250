import React from 'react';

const SavedGrids = (props) => {
	const { savedGrids, changeActiveGrid } = props;
	if (savedGrids) {
		return (
			<div>
				<label style={{ paddingLeft: '10px' }}>Select Saved Grid:</label>
				{savedGrids.length > 0 ? (
					<select onChange={(value) => changeActiveGrid(value.target.value)}>
						{savedGrids.map((grid) => {
							return (
								<option key={grid.id} value={grid.name}>
									{grid.name}
								</option>
							);
						})}
					</select>
				) : null}
			</div>
		);
	} else {
		return <label style={{ paddingLeft: '10px' }}>Select Saved Grid:</label>;
	}
};
export default SavedGrids;
