import * as firebase from 'firebase';
import 'firebase/firestore';
const firebaseConfig = {
	apiKey: 'AIzaSyAzHJzBLP5jKqJxUDbaZzkaaSeJShZ2R3c',
	authDomain: 'lightsku-8c7f6.firebaseapp.com',
	databaseURL: 'https://lightsku-8c7f6.firebaseio.com',
	projectId: 'lightsku-8c7f6',
	storageBucket: 'lightsku-8c7f6.appspot.com',
	messagingSenderId: '653825781140',
	appId: '1:653825781140:web:36c1d56f47b6b1d7803381'
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const client = db.collection('clients').doc('demo');

export const lights = db.collection('clients').doc('demo').collection('lights');
export const positions = db.collection('clients').doc('demo').collection('positions');

export default {
	lights: lights,
	positions: positions,
	db: db
};
