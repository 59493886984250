import React from 'react';

const MoveLights = (position, client) => {
	client.lights
		.doc('channel1')
		.update({ current_x: position.leftPosition[0], current_y: position.leftPosition[1] })
		.catch((err) => {
			console.error(err);
		});

	client.lights
		.doc('channel2')
		.update({ current_x: position.centerPosition[0], current_y: position.centerPosition[1] })
		.catch((err) => {
			console.error(err);
		});

	client.lights
		.doc('channel3')
		.update({ current_x: position.rightPosition[0], current_y: position.rightPosition[1] })
		.catch((err) => {
			console.error(err);
		});

	return null;
};
export default MoveLights;
