import React from 'react';
import MoveLights from './moveLights';

const MoveLightButton = (props) => {
	const { index, item, items, setItems, highlighted, setHighlighted, client, locationID } = props;
	console.log(`LOCATION ID: ${JSON.stringify(item)}`);
	return (
		<div>
			<button
				style={{
					width: '100px',
					height: '50px',
					marginBottom: '5px',
					marginTop: '10px'
				}}
				onClick={(e) => {
					let tempItems = items;
					tempItems[index].selected = !tempItems[index].selected;
					setItems(items);
					if (highlighted === index) {
						setHighlighted(-1);
					} else {
						setHighlighted(index);
					}
					MoveLights(
						{
							leftPosition: item.leftPosition,
							centerPosition: item.centerPosition,
							rightPosition: item.rightPosition
						},
						client
					);
				}}
			>
				Move
			</button>
		</div>
	);
};
export default MoveLightButton;
