const PopulateItems = (currentGrid, colCount, rowCount, setItems) => {
	let totalSections = colCount * rowCount;
	let colIndex = 1;
	let rowIndex = 0;
	const arr = currentGrid.map((rect, i) => {
		console.log(`CURRENT RECT: ${JSON.stringify(rect)}`);
		if (i % colCount === 0) {
			rowIndex += 1;
			colIndex = 1;
		} else {
			colIndex++;
		}

		return {
			name: rect.name,
			leftPosition: [ rect.leftPosition[0], rect.leftPosition[1] ],
			rightPosition: [ rect.rightPosition[0], rect.rightPosition[1] ],
			centerPosition: [ rect.centerPosition[0], rect.centerPosition[1] ],
			selected: false
		};
	});
	setItems(arr);
};
// const styles = StyleSheet.create({});
export default PopulateItems;
